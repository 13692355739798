import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import './styles.scss'

const HeaderContentSolution = ({ description, icon }) => {
  return (
    <section className="header-content-solution">
      <div className="header-content-solution__container">
        <div className="header-icon-content">
          <div className="header-icon-content__img">
            {icon === 'project' ? (
              <StaticImage
                src="../../../assets/images/project-icon.webp"
                loading="lazy"
                objectFit="contain"
                quality={95}
                formats={['auto', 'webp', 'avif']}
                alt="Digital Republic"
              />
            ) : icon === 'squads' ? (
              <StaticImage
                src="../../../assets/images/squads-icon.webp"
                loading="lazy"
                objectFit="contain"
                quality={95}
                formats={['auto', 'webp', 'avif']}
                alt="Digital Republic"
              />
            ) : icon === 'marketing' ? (
              <StaticImage
                src="../../../assets/images/marketing-icon.webp"
                loading="lazy"
                objectFit="contain"
                quality={95}
                formats={['auto', 'webp', 'avif']}
                alt="Digital Republic"
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="header-description-content">
          <div
            className="header-description-content__container"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </section>
  )
}

export default HeaderContentSolution
