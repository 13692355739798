import React, { useState, useEffect } from 'react'
import useStaticImage from '../../../hooks/useStaticImage'
import { GatsbyImage } from 'gatsby-plugin-image'
import './styles.scss'

const ContentSolution = ({ icon, title, description }) => {
  const [getImage] = useStaticImage()
  const [hasTitle, setHasTitle] = useState('with-title')

  const functionTitle = () => {
    !title && setHasTitle('without-title')
  }

  useEffect(() => {
    functionTitle()
  }, [])

  return (
    <section className="content-solution">
      <div className="content-solution__container">
        <div className={`icon-content--${hasTitle}`}>
          <div className="icon-content__img">
            {icon && <GatsbyImage image={getImage(icon)} alt="content image" />}
          </div>
        </div>
        {title && (
          <div className={'title-content'}>
            <h3 className="title-content__container">{title}</h3>
          </div>
        )}
        <div className={'description-content'}>
          <p
            className="description-content__container"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </section>
  )
}

export default ContentSolution
