import * as React from 'react'
import Layout from '../../templates/layout'
import SloganPage from '../../components/molecules/SloganPage'
import Seo from '../../helpers/seo'
import HeaderContentSolution from '../../components/organisms/HeaderContentSolution'
import ContentSolution from '../../components/organisms/ContentSolution'
import CardsInformation from '../../components/molecules/CardsInformation'
import recruitmentJson from './recruitment.json'
import ContactForm from '../../components/organisms/ContactForm'
import CasesRelational from '../../components/organisms/CasesRelational'
import { assignIDToSolutionsJson } from '../../helpers/assignIDToSolutionsJson'

const ServicesRecruitment = () => {
  const { hunting, relational } = assignIDToSolutionsJson(recruitmentJson)

  const cardContent = [
    {
      id: 1,
      heading: 'Hunting',
      content: () => {
        return hunting.map((data) => {
          return (
            <ContentSolution
              title={data.title}
              description={data.description}
              key={data.id}
            />
          )
        })
      },
      label: 'orange-dark',
      selected: 'close',
    },
  ]

  return (
    <Layout page="ServicesRecruitment">
      <SloganPage title={'Recrutamento em TI'} type={'recruitment'} />
      <HeaderContentSolution
        icon={'squads'}
        description={`<p>Encontrar o talento certo é fundamental para o sucesso de qualquer projeto de tecnologia. Nosso serviço
        de Recrutamento em TI se concentra em entender suas necessidades específicas e encontrar os melhores
        profissionais do mercado. Através de um processo de seleção rigoroso e eficiente, garantimos que você
        obtenha não apenas habilidades técnicas, mas também uma compatibilidade comportamental com sua
        equipe e cultura organizacional.</p>`}
      />
      <CardsInformation cardContent={cardContent} />
      <ContactForm />
      <CasesRelational relationalData={relational} currentCategory="Recrutamento em TI" />
    </Layout>
  )
}

export const Head = () => <Seo title="Serviços" />

export default ServicesRecruitment
