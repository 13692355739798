import React from 'react'
import './styles.scss'

const CardsInformation = ({ cardContent }) => {
  return (
    <div cardContent={cardContent}>
      <div className="cards-information">
        <div className="cards-information__container">
          {cardContent?.map((item) => (
            <div className="cards-information__container__card" key={item.label}>
              <h3 className={`cards-information__container__card-text--${item.label}`}>
                {item.heading}
              </h3>
              <div>{item.content()}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CardsInformation
