import React from "react"
import "./styles.scss"

const SloganPage = props => {

  const { title, type } = props

  return (
    <section className={`solutions__page solutions__page-${type}`}>
      <div className="solutions__page__border">
        <h1>
          <span>{title}</span>
        </h1>
      </div>
    </section>
  )

}

export default SloganPage
