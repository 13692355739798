import React from "react"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"
import "./styles.scss"
import { EMAIL_CONTACT } from "../../../helpers/constants"

const ContactForm = () => {
  const location = useLocation()

  const onSubmit = async e => {
    e.preventDefault()
    const formValues = Object.fromEntries(new FormData(e.target))
    const url =
      "https://vx3nuvayzb.execute-api.us-east-1.amazonaws.com/prod/contact"
    const payload = {
      ...formValues,
      to: EMAIL_CONTACT,
      subject: `${formValues.name}'s message from ${location.pathname} website`,
    }

    await fetch(url, { method: "POST", body: JSON.stringify(payload) })
      .then(() => navigate("/thanks"))
      .catch(err => console.error(err))
  }

  return (
    <section className="contact-form">
      <div className="contact-form__container">
        <div className="contact-form__container-text">
          <p>Fale com um especialista</p>
        </div>
        <form
          className="contact-form__container-inputs"
          onSubmit={e => onSubmit(e)}
        >
          <input required type="text" placeholder="Nome" name="name" />
          <input required type="email" placeholder="E-mail" name="email" />
          <input required type="text" placeholder="Mensagem" name="message" />
          <button
            className="contact-form__container-inputs--button"
            type="submit"
          >
            Enviar
          </button>
        </form>
      </div>
    </section>
  )
}

export default ContactForm
