import { v4 as uuidv4 } from 'uuid'

export const assignIDToSolutionsJson = (data) =>
  Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      return [
        key,
        [
          ...value.map((solution) => ({
            ...solution,
            id: uuidv4(),
          })),
        ],
      ]
    }),
  )
